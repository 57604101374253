<template>
  <div class="box">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>售后订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>用料单审批记录</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 内容开始 -->
    <el-card class="box-card">
      <div class="nav">
        <div class="main">
          <div class="titles">订单编号</div>
          <el-input style="width: 300px" placeholder="请输入订单编号" v-model="ticket_order_no" class="input-with-select" clearable @clear="handleEmptyno">
            <el-button slot="append" icon="el-icon-search" @click="ticketOrdernofun()"></el-button>
          </el-input>
        </div>
        <div class="main">
          <div class="titles">用料工单编号</div>
          <el-input style="width: 300px" placeholder="请输入工单编号" v-model="matter_order_no" class="input-with-select" clearable @clear="handleEmptymatter">
            <el-button slot="append" icon="el-icon-search" @click="worknofun()"></el-button>
          </el-input>
        </div>

        <div class="main">
          <div class="titles">提交时间</div>
          <el-date-picker
            :picker-options="pickerOptions"
            @change="gettime"
            v-model="timeFrom"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
      </div>

      <div class="nav">
        <div class="main">
          <div class="titles">服务工程师</div>
          <div>
            <!-- <el-select v-model="maintain_user" placeholder="未选择" @clear="handleEmptyuser" clearable>
              <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value" @click.native="choicedrug(item)"></el-option>
            </el-select> -->
            <el-autocomplete
              clearable
              @clear="handleEmptyuser"
              :trigger-on-focus="false"
              placeholder="请输入工程师名称"
              v-model="gongchengs"
              :fetch-suggestions="shenvalidateCounts"
              value-key="name"
              @select="shenhandleSelect($event, '申请人')"
            ></el-autocomplete>
          </div>
        </div>
        <div class="main">
          <div class="titles">提报事项</div>
          <el-select v-model="matter_type" placeholder="请选择" clearable @clear="handleEmptytype">
            <el-option v-for="item in types" :key="item.value" :label="item.name" :value="item.value" @click.native="typechenge(item)"></el-option>
          </el-select>
        </div>
      </div>

      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="ticket_order_no" label="订单编号" width="180"></el-table-column>
        <el-table-column prop="matter_order_no" label="用料审批单编号" width="200"></el-table-column>
        <el-table-column prop="created_time" label="提交时间" width="150" align="center"></el-table-column>
        <el-table-column prop="maintain_user" label="服务工程师" align="center" width="150"></el-table-column>
        <el-table-column prop="matter_type_desc" label="提报事项" width="220" align="center"></el-table-column>
        <el-table-column label="金额(元)" align="center" width="150">
          <template slot-scope="scope">
            <div>{{ scope.row.amount / 100 }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-view" @click="detail(scope.row.matter_order_id, scope.row.matter_type)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>

    <!-- 出库详情 -->
    <el-dialog title="出库详情" :visible.sync="outputVisible" center>
      <el-form :model="outputlist">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="8">
            <el-form-item label="出库仓库" label-width="90px">
              <div class="el-select"><el-input v-model="outputlist.house_name" disabled></el-input></div>
            </el-form-item>
          </el-col>

          <el-col :span="8"></el-col>
        </el-row>
      </el-form>
      <el-table :data="outputlist.items" border style="width: 85%; margin-left: 10%">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="goods_no" label="货物编号" width="170" align="center"></el-table-column>
        <el-table-column prop="goods_name" label="货物名称" width="170" align="center"></el-table-column>
        <el-table-column prop="goods_desc" label="货物详情" align="center" width="170"></el-table-column>
        <el-table-column prop="goods_num" label="货物数量" align="center" width="100"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="outputVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="outputVisible = false">关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 自行采购详情 -->
    <el-dialog title="自行采购详情" :visible.sync="buyVisible" center>
      <el-table :data="buylist.items" border style="width: 85%; margin-left: 10%">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column prop="goods_no" label="货物编号" width="170" align="center"></el-table-column>
        <el-table-column prop="goods_name" label="货物名称" width="170" align="center"></el-table-column>
        <el-table-column prop="goods_desc" label="货物详情" align="center" width="170"></el-table-column>
        <el-table-column prop="goods_num" label="货物数量" align="center" width="100"></el-table-column>
        <el-table-column label="单项小计" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.goods_amount / 100 }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="tongs">费用总计{{ buylist.amount / 100 }}元</div>
      <el-form :model="buylist" class="buyfrom">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="10">
            <el-form-item label="支付截图" label-width="125px">
              <el-image
                :z-index="5000"
                style="width: 100px; height: 100px"
                v-for="(item, index) in buylist.pay_images"
                :key="index"
                :src="item"
                :preview-src-list="buylist.pay_images"
              ></el-image>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="收据截图" label-width="125px">
              <el-image
                :z-index="5000"
                style="width: 100px; height: 100px"
                v-for="(item, index) in buylist.receipt_images"
                :key="index"
                :src="item"
                :preview-src-list="buylist.receipt_images"
              ></el-image>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="8">
            <el-form-item label="是否有发票" label-width="90px">
              <el-radio v-model="buylist.buy_bill" :label="1" disabled>有</el-radio>
              <el-radio v-model="buylist.buy_bill" :label="2" disabled>无</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="buyVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="buyVisible = false">关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 外协详情 -->
    <el-dialog title="外协详情" :visible.sync="helpVisible" center width="40%">
      <el-form :model="helplist">
        <el-row>
          <el-col :span="23">
            <el-form-item label="外协事务名称" label-width="125px">
              <div class="el-select"><el-input v-model="helplist.help_name" disabled></el-input></div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23">
            <el-form-item label="事务详情" label-width="125px">
              <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="helplist.help_detail"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23">
            <el-form-item label="外协费用" label-width="125px">
              <div class="el-select"><el-input v-model="helpamount" disabled></el-input></div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="23">
            <el-form-item label="支付截图" label-width="125px">
              <el-image
                :z-index="5000"
                style="width: 100px; height: 100px"
                v-for="(item, index) in helplist.pay_images"
                :key="index"
                :src="item"
                :preview-src-list="helplist.pay_images"
              ></el-image>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="23">
            <el-form-item label="收据截图" label-width="125px">
              <el-image
                :z-index="5000"
                style="width: 100px; height: 100px"
                v-for="(item, index) in helplist.receipt_images"
                :key="index"
                :src="item"
                :preview-src-list="helplist.receipt_images"
              ></el-image>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="8">
            <el-form-item label="是否有发票" label-width="90px">
              <el-radio v-model="helplist.buy_bill" :label="1" disabled>有</el-radio>
              <el-radio v-model="helplist.buy_bill" :label="2" disabled>无</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="helpVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="helpVisible = false">关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 业务费用详情 -->
    <el-dialog title="业务费用详情" :visible.sync="businessVisible" center width="40%">
      <el-form :model="businesslist">
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="8">
            <el-form-item label="业务费用" label-width="90px">
              <div class="el-select"><el-input v-model="businesslistamount" disabled></el-input></div>
            </el-form-item>
          </el-col>

          <el-col :span="8"></el-col>
        </el-row>

        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="8">
            <el-form-item label="费用详情" label-width="90px">
              <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="businesslist.business_detail"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8"></el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="businessVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="businessVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Drop } from '../../../api/slogong'
import { Getren } from '../../../api/shouhuo.js'
import Fenye from '../../../components/Fenye'
import { Matterorder, Orderinfo } from '../../../api/afterOrder.js'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      caigoulist: [],
      gongchengs: '',
      timeFrom: [],
      tableData: [],
      pageData: {
        page: 1,
        size: 10,
        count: 0
      },
      renlist: [],
      outputVisible: false,
      buyVisible: false,
      helpVisible: false,
      businessVisible: false,
      outputlist: {},
      buylist: {},
      helplist: {},
      helpamount: '',
      businesslist: {},
      businesslistamount: '',
      ///////
      ticket_order_no: '',
      matter_order_no: '',
      maintain_user: '',
      matter_type: '',
      from_time: '',
      end_time: '',
      types: [
        { name: '出库', value: 'output' },
        { name: '自行采购', value: 'buy' },
        { name: '外协费用', value: 'help' },
        { name: '业务费用', value: 'business' }
      ]
    }
  },
  mounted() {
    this.Matterorders()
    this.Getrenlist()
  },
  methods: {
    // 搜索工程师后的数据
    shenvalidateCounts(queryString, cb) {
      // 调用搜索方法
      this.Crops(queryString, cb)
    },

    // 选中工程师
    shenhandleSelect(e) {
      this.maintain_user = e.value
      this.Matterorders()
    },

    // 详情
    detail(id, matter_type) {
      // console.log(id, matter_type)
      if (matter_type === 'output') {
        this.outputVisible = true
        this.Orderinfos(id)
      } else if (matter_type === 'buy') {
        this.buyVisible = true
        this.Orderinfos(id)
      } else if (matter_type === 'help') {
        this.helpVisible = true
        this.Orderinfos(id)
      } else if (matter_type === 'business') {
        this.businessVisible = true
        this.Orderinfos(id)
      }
    },

    handleEmptyno() {
      this.ticket_order_no = ''
      this.Matterorders()
    },

    handleEmptymatter() {
      this.matter_order_no = ''
      this.Matterorders()
    },

    handleEmptyno() {
      this.ticket_order_no = ''
      this.Matterorders()
    },

    // 订单编号
    ticketOrdernofun() {
      if (this.ticket_order_no === '') {
        this.$message({
          message: '请输入订单编号',
          type: 'warning'
        })
      } else {
        this.Matterorders()
      }
    },

    // 用料单编号
    worknofun() {
      if (this.matter_order_no === '') {
        this.$message({
          message: '请输入用料工单编号',
          type: 'warning'
        })
      } else {
        this.Matterorders()
      }
    },

    // 维修工程师
    choicedrug() {
      this.Matterorders()
    },

    // 用料单类型
    typechenge() {
      this.Matterorders()
    },

    handleEmpty() {
      this.ticket_order_no = ''
      this.matter_order_no = ''
      this.Matterorders()
    },

    handleEmptytype() {
      this.matter_type = ''
      this.Matterorders()
    },

    handleEmptyuser() {
      this.maintain_user = ''
      this.Matterorders()
    },

    // 日期选中事件
    gettime(timeFrom) {
      // console.log(timeFrom)
      if (timeFrom !== null) {
        this.from_time = timeFrom ? timeFrom[0] : ''
        this.end_time = timeFrom ? timeFrom[1] : ''
        this.Matterorders()
      } else {
        this.from_time = ''
        this.end_time = ''
        this.Matterorders()
      }
    },

    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Matterorders()
    },

    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Matterorders()
    },

    // 列表
    async Matterorders() {
      const { data } = await Matterorder({
        ...this.pageData,
        ticket_order_no: this.ticket_order_no,
        matter_order_no: this.matter_order_no,
        maintain_user: this.maintain_user,
        matter_type: this.matter_type,
        from_time: this.from_time,
        end_time: this.end_time
      })
      // console.log(data)
      this.tableData = data.data.data
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    async Orderinfos(order_id) {
      const { data } = await Orderinfo({ order_id: order_id })
      // console.log(data)
      if (data.data.matter_type === 'output') {
        this.outputlist = data.data
      } else if (data.data.matter_type === 'buy') {
        this.buylist = data.data
      } else if (data.data.matter_type === 'help') {
        this.helplist = data.data
        this.helpamount = data.data.amount / 100
      } else if (data.data.matter_type === 'business') {
        this.businesslist = data.data
        this.businesslistamount = data.data.amount / 100
      }
    },

    // 工程师
    async Getrenlist() {
      const { data } = await Getren()
      this.renlist = data.data
    },

    // 工程师
    async Crops(name, cd) {
      const { data } = await Drop({ name })
      // console.log(data.data)
      this.caigoulist = data.data
      cd(data.data)
    }
  }
}
</script>

<style scoped lang='less'>
.tongs {
  width: 85%;
  height: 50px;
  line-height: 50px;
  border: 1px solid #ebeef5;
  margin-left: 10%;
  box-sizing: border-box;
  padding-left: 2%;
}
.buyfrom {
  margin-top: 30px !important;
}
.nav {
  width: 100%;
  display: flex;
  //   justify-content: space-between;
  box-sizing: border-box;
  height: 50px;
  .main {
    width: 30%;
    // border: 1px solid #000;
    height: 100%;
    margin-left: 5px;
    display: flex;
    align-items: center;
    .titles {
      width: 17%;
      font-size: 13px;
    }
  }
}
</style>